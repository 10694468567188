module.exports = [{
      plugin: require('C:/wamp64/www/repo-github-app-generator/gatsby-starter-cssinjs/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":""},
    },{
      plugin: require('C:/wamp64/www/repo-github-app-generator/gatsby-starter-cssinjs/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"nofollow noopener noreferrer"}},{"resolve":"gatsby-remark-images","options":{"maxWidth":830,"quality":90,"withWebp":true,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-autolink-headers","options":{"maintainCase":false}}]},
    },{
      plugin: require('C:/wamp64/www/repo-github-app-generator/gatsby-starter-cssinjs/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/wamp64/www/repo-github-app-generator/gatsby-starter-cssinjs/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/wamp64/www/repo-github-app-generator/gatsby-starter-cssinjs/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
