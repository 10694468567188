// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-starter-cssinjs\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-starter-cssinjs\\src\\templates\\post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-category-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-starter-cssinjs\\src\\templates\\category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-pages-categories-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-starter-cssinjs\\src\\pages\\categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-contact-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-starter-cssinjs\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-starter-cssinjs\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-starter-cssinjs\\src\\pages\\success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

